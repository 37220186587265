import React from "react"
import Layout from "../components/layout"
import MasonryPage from "../components/masonry"
import "../pages/About.css"

import Eduardo from "../images/About/Eduardo.jpeg"
import Horses from "../images/About/Eatinghorses.jpeg"

export default function About() {
    return (
        <>
          <Layout>
            <div className="bg-color-about">
                <div className="container-box1">

                    <h1 className="page-title">EC Horses</h1>

                    <div className="about-infopics">
                        <div className="about-info">
                            <p className="about-p">EC Horses is a passion project for married couple Eduardo Gouveia and Claudia Garcia de Paredes, who have been enamored by equestrian sport from an early age. Their desire to live a full equestrian life took them to Belgium, the center of show jumping sport and breeding.</p>
                            <p className="about-p">At EC Horses, we believe not only in the importance of genetic contribution from a proven mare and stallion but also consider their starting developing years crucial for their sport longevity. Subsequently, we established a boutique breeding program using carefully selected mares for their genetic lines and carriers themselves of qualities we consider essential for future generations of jumpers to possess. We retain several of our breeding products to develop them for future sport, with a program that allows them mature at their own pace, that evolves following their needs.</p>
                            <p className="about-p">Our mission is to provide our clients and future riders with promising quality horses for long and healthy sports careers.</p>
                            <p className="about-p">Our location in Peer, Belgium, is vital for our mission project giving us access to the best venues and veterinary services in Belgium, Germany, and Holland. From our farm we have access to world prominent venues such as Sentower Park - 18 km, Tops International Arena - 20 km, Equestrian Centre de Peelbergen- 50 km, and CHIO Aachen and Azelhof - 75 km.</p>
                        </div>

                        <div className="about-pics">
                            <img src={Eduardo} alt="Jokey jumping with horse" />
                            <img id="horsesImg" src={Horses} alt="Horses eating grass" />
                        </div>
                    </div>

                </div>

                <div className="container-box-services">
                    <h1 className="page-title">Services</h1>
                    <div className="flex-services">
                        <div className="service">
                          <p>
                            Breeding
                          </p>
                        </div>

                        <div className="service">
                          <p>
                            Training / Developing
                          </p>
                        </div>

                        <div className="service">
                         <p>
                          Trading
                         </p>
                        </div>

                        <div className="service">
                          <p>
                            Scouting
                          </p>
                        </div>
                    </div>
                </div>

                <div className="container-box" id="ec-farm">
                    <h1 className="page-title">EC Farm</h1>
                    <div className="gallery-container">
                        <MasonryPage />
                    </div>
                </div>
            </div>
          </Layout>
        </>
    )
}
