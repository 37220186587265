import React from "react";

import fruittree from "../images/About/fruittree.jpeg"
import trees from "../images/About/trees.jpeg"
import terreno2 from "../images/About/terreno2.jpeg"
import fields from "../images/About/fields.jpeg"
import horse from "../images/About/manandhorse.jpeg"
import terreno from "../images/About/terreno.jpeg"
import horses from "../images/About/Eatinghorses.jpeg"
import winter from "../images/About/winter.jpeg"



import "../components/masonry.css";

class MasonryPage extends React.Component {
    render() {
        return (
            <>
                <div id="masonry">
                  <img className="img-masonry-small" id="img1" src={terreno2} />
                  <img className="img-masonry-small" id="img2" src={fruittree} />
                  <img className="img-masonry-small" id="img3" src={trees} />
                  <img className="img-masonry" id="img4" src={fields} />
                  <img className="img-masonry" id="img5" src={horse} />
                  <img className="img-masonry-small" id="img6" src={terreno} />
                  <img className="img-masonry-small" id="img7" src={horses} />
                  <img className="img-masonry-small" id="img8" src={winter} />
                </div>
            </>
        );
    }
}

export default MasonryPage
